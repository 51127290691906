<template>
    <div class="aplayz_modal">
        <div class="select_modal_wrap">
            <ul class="flex_column">
                <li class="flex_space_between selectvocal_wrap">
                    <h2 class="selectvocal_title">보컬 유무를 선택해주세요.</h2>
                    <img
                        src="/media/img/space/close.svg"
                        class="select_vocal_close cursor"
                        @click="$emit('close-selectvocal')"
                        alt="닫기"
                    />
                </li>
                <li v-if="isshowjazz" class="flex_column selectvocal_jazz">
                    <label class="jazz_title" for="jazz">재즈</label>
                    <ul class="flex_space_between jazz_list">
                        <li class="jazz_item_wrap">
                            <label class="jazz_item cursor" :class="{ active: parseInt(jazzVocal) === 0 }" for="jazzvocal0"
                            >보컬 있음</label
                            >
                            <input type="radio" id="jazzvocal0" hidden value="0" v-model="jazzVocal" />
                        </li>
                        <li class="jazz_item_wrap">
                            <label class="jazz_item cursor" :class="{ active: parseInt(jazzVocal) === 1 }" for="jazzvocal1"
                            >보컬 없음</label
                            >
                            <input type="radio" id="jazzvocal1" hidden value="1" v-model="jazzVocal" />
                        </li>
                        <li class="jazz_item_wrap">
                            <label class="jazz_item cursor" :class="{ active: parseInt(jazzVocal) === 2 }" for="jazzvocal2"
                            >상관 없음</label
                            >
                            <input type="radio" id="jazzvocal2" hidden value="2" v-model="jazzVocal" />
                        </li>
                    </ul>
                </li>
                <li v-if="isshowclassic" class="flex_column selectvocal_classic">
                    <label class="classic_title" for="jazz">클래식</label>
                    <ul class="flex_space_between classic_list">
                        <li class="classic_item_wrap">
                            <label class="classic_item cursor" :class="{ active: parseInt(classicVocal) === 0 }" for="vocal0"
                            >보컬 있음</label
                            >
                            <input type="radio" id="vocal0" hidden value="0" v-model="classicVocal" />
                        </li>
                        <li class="jazz_item_wrap">
                            <label class="classic_item cursor" :class="{ active: parseInt(classicVocal) === 1 }" for="vocal1"
                            >보컬 없음</label
                            >
                            <input type="radio" id="vocal1" hidden value="1" v-model="classicVocal" />
                        </li>
                        <li class="jazz_item_wrap">
                            <label class="classic_item cursor" :class="{ active: parseInt(classicVocal) === 2 }" for="vocal2"
                            >상관 없음</label
                            >
                            <input type="radio" id="vocal2" hidden value="2" v-model="classicVocal" />
                        </li>
                    </ul>
                </li>
                <li class="flex_end selectvocal_btn_group">
                    <button class="selectvocal_btn cancel" @click="$emit('close-selectvocal')">취소</button>
                    <button :disabled="btnDisabled" class="selectvocal_btn active" @click="saveVocal">적용</button>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
  name: 'SelectVocal',
  props: {
    isshowjazz: {
      type: Boolean,
      required: false,
      default: false
    },
    isshowclassic: {
      type: Boolean,
      required: false,
      default: false
    },
    jazzvocalvalue: {
      type: Number,
      required: false,
      default: -1
    },
    classicvocalvalue: {
      type: Number,
      required: false,
      default: -1
    }
  },
  emits: ['close-selectvocal', 'save-vocal'],
  data () {
    return {
      jazzVocal: -1,
      classicVocal: -1
    };
  },

  created () {
    this.jazzVocal = this.jazzvocalvalue;
    this.classicVocal = this.classicvocalvalue;
  },

  mounted () {},

  methods: {
    saveVocal () {
      this.$emit('save-vocal', {
        jazzVocal: this.jazzVocal,
        classicVocal: this.classicVocal
      });
    }
  },
  computed: {
    btnDisabled () {
      if (this.isshowjazz && this.isshowclassic) {
        if (this.jazzVocal !== -1 && this.classicVocal !== -1) {
          return false;
        }
        return true;
      } else if (this.isshowjazz) {
        if (this.jazzVocal !== -1) {
          return false;
        } else {
          return true;
        }
      } else if (this.isshowclassic) {
        if (this.classicVocal !== -1) {
          return false;
        } else {
          return true;
        }
      }
      return true;
    }
  }
};
</script>
<style scoped src="@/assets/css/common/modal.css"></style>
<style scoped src="@/assets/css/space/selectvocal.css"></style>
