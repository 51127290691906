import { render, staticRenderFns } from "./SelectVocal.vue?vue&type=template&id=859eed6a&scoped=true"
import script from "./SelectVocal.vue?vue&type=script&lang=js"
export * from "./SelectVocal.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/common/modal.css?vue&type=style&index=0&id=859eed6a&prod&scoped=true&lang=css&external"
import style1 from "@/assets/css/space/selectvocal.css?vue&type=style&index=1&id=859eed6a&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "859eed6a",
  null
  
)

export default component.exports